<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号" align="center">
    </el-table-column>
    <el-table-column
      prop="balance_type_name"
      label="金额出入类型"
      align="center"
    >
    </el-table-column>
    <el-table-column prop="amount" label="金额" align="center">
    </el-table-column>
    <el-table-column prop="post_balance" label="余额" align="center">
    </el-table-column>
    <el-table-column prop="memo" label="备注" align="center"> </el-table-column>
    <el-table-column prop="created_at" label="时间" align="center">
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //充值
    handleRecharge() {},
    //提现
    handleWithdraw() {},
  },
};
</script>
<style lang="less" scoped>
</style>