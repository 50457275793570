<template>
  <div class="finance-container">
    <finance-nav @change="getAgentBalanceLog"></finance-nav>
    <list :list="list"></list>
  </div>
</template>
<script>
import List from "./components/List";
import FinanceNav from "./components/Nav";
export default {
  name: "FinanceRecord",
  components: {
    FinanceNav,
    List,
  },
  data() {
    return {
      list: [],
    };
  },

  methods: {
    //获取代理的充值和使用情况
    async getAgentBalanceLog(data) {
      const res = await this.$store.dispatch("getAgentBalanceLog", data);
      this.list = res.balances;
    },
  },
};
</script>
<style lang="less" scoped>
.finance-container {
  width: 100%;
}
</style>